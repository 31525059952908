ul {
  list-style: none;
  padding: 0;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.logoImg-admin{
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.layout-text{
  color: #ffff;
  margin-left: 5px;
}
.header-back {
  display: flex;
  min-width: 100px;
  line-height: 50px;
  font-size: 16px;
  .anticon {
    line-height: 50px;
    margin: 0px 20px 0px 10px;
  }
}
.ant-upload-list-item-error{
  .ant-tooltip-content{
    display: none;
  }
}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.65)  !important
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon{
  color: rgba(0, 0, 0, 0.65)  !important
}

.Custorm { 
  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

.menu-wrapper.ant-menu-root.ant-menu-vertical {
  background-color: #001529;
  color: white;
  font-weight: 600;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: white !important;
  color: #1890ff;
}
