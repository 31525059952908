.history {
    .search-input {
        margin-bottom: 20px;

        &-wrapper {
            height: 32px;
            width: 100%;
        }
    }

    .w-200 {
        width: 200px;
    }

    .w-250 {
        width: 250px;
    }

    .col-md-3 {
        width: auto;
    }
}