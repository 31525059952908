.detail {
    &-user-header {
        height: 50px;
        background-color: #003a70;
        padding: 12px !important;
        border-radius: 5px 5px 0px 0px;
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
    }

    &-user-body {
        margin-top: 20px;
    }

    &-ticket-wrapper {
        .tabs {
            color: #FFFFFF;
        }
    
        .active-label {
            color: #e4f2ff;
            font-size: 16px;
            font-weight: 700;
        }
    
        .normal-label {
            color: white;
            font-size: 16px;
            font-weight: 700;
        }
    
        .ant-tabs-nav-wrap {
            height: 50px;
            font-size: 24px !important;
            background-color: #003a70;
            padding: 12px !important;
            border-radius: 5px 5px 0px 0px;
        }
    
        .ant-tabs-ink-bar {
            background: #FFFFFF;
        }
    
    }
}