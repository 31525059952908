.home {
  .card{
    background: #FDFDFD;
    border-radius: 10px;
    border: 1px solid #8E8E93;
    padding: 16px;
    &__name {
      color:  #007AFF;
      font-size: 16px;
      font-weight: '600';
    }
    &__des {
      color:  #221F1F;
      font-size: 14px;
      font-weight: 'normal';
    }
  }
}

.coupon_code:hover { 
  color:  #007AFF;
}